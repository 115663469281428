import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom"
import { SnackbarProvider } from 'notistack'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from 'styled-components';
import { iniFirebase } from './firebase'
import { UserProvider } from './contexts/User'
import {
  PublicScreen,
  PrivateScreen,
  AuthLogin,
  AuthRegister,
  DashboardScreen,
  MapToBusiness,
  AuthBusinessUserInvite,
} from './screens'

import DashboardHome from './components/DashboardHome'
import DashboardFuelStations from './components/DashboardFuelStations'
import DashboardInsights from './components/DashboardInsights'
import DashboardSettings from './components/DashboardSettings'
import FuelStation from './components/FuelStation'
import FuelStationLocation from './components/FuelStationLocation'

type AppProviderProps = {
  children: React.ReactNode
}

const customTheme = createTheme({
  palette: {
    primary: {
      dark: '#0c7739',
      light: '#41bb74',
      main: '#12ab52',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#2979ff',
      dark: '#1c54b2',
      light: '#5393ff',
      contrastText: '#FFFFFF'
    }
  }
});

/**
 * AppUI for non materialui styles
 */
function AppuiProvider ({ children }: AppProviderProps) {
  return <div className="appui">
    { children }
  </div>
}

function FirebaseProvider ({ children }: AppProviderProps) {
  iniFirebase()

  return <>
    { children }
  </>
}

function AppRouter () {
  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<PrivateScreen />}>
        <Route path="business/:businessAccountId" element={<DashboardScreen />}>
          <Route path="fuel-stations">
            <Route path="add-location" element={<FuelStationLocation type="ADD" />} />
            <Route path=":fuelStationId" element={<FuelStation />} />
            <Route path="" element={<DashboardFuelStations />} />
          </Route>
          <Route path="insights" element={<DashboardInsights />} />
          <Route path="settings" element={<DashboardSettings />} />
          <Route path="" element={<DashboardHome />} />
        </Route>
        <Route path="business" element={<MapToBusiness />} />
        <Route path="" element={<MapToBusiness />} />
      </Route>
      <Route path="/" element={<PublicScreen />}>
        <Route path="login" element={<AuthLogin />} />
        <Route path="register" element={<AuthRegister />} />
      </Route>
      <Route path="/join-business-account/:businessUserInviteId" element={<AuthBusinessUserInvite />} />
    </Routes>
  </BrowserRouter>
}

const AppProvider = (props: AppProviderProps) => {
  const { children } = props
  return <AppuiProvider>
    <CssBaseline />
    <FirebaseProvider>
      <UserProvider>
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
          <MuiThemeProvider theme={customTheme}>
            <ThemeProvider theme={customTheme}>
              { children }
            </ThemeProvider>
          </MuiThemeProvider>
        </SnackbarProvider>
      </UserProvider>
    </FirebaseProvider>
  </AppuiProvider>
}

const App = () => {
  return <AppProvider>
    <AppRouter />
  </AppProvider>
}



export default App;
