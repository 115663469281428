import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { Theme, useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'

import FuelStationProducts from './FuelStationProducts'
import FuelStationLocation from './FuelStationLocation'
import FuelStationSettings from './FuelStationSettings'
import FuelStationEngagement from './FuelStationEngagement'
import FuelStationOperations from './FuelStationOperations'
import FuelStationTransactions from './FuelStationTransactions'
import Error40X from './Error40X'

import Logger from '../services/logger'
import useCoreApi from '../hooks/useCoreApi'
import useFuelStationBrand from '../hooks/useFuelStationBrand'
import { useUser } from '../hooks/useUser'
import * as Types from '../types'

import ShellJpg  from '../static/images/brands/shell.jpg'
import PetronJpg  from '../static/images/brands/petron.jpg'
import PhoenixJpg  from '../static/images/brands/phoenix.jpg'
import CaltexJpg  from '../static/images/brands/caltex.jpg'
import SeaOilJpg  from '../static/images/brands/seaoil.jpg'
import UnioilJpg  from '../static/images/brands/unioil.jpg'
import TotalJpg  from '../static/images/brands/total.jpg'

const logger = new Logger({
  filePath: '@/components/FuelStation'
})

type StyledTheme = {
  theme: Theme,
}

export type GetFuelStationResponse = {
  data: {
    result?: Types.FuelStation
  }
}

export type GetFuelProductsResponse = {
  data: {
    result?: Types.FuelProduct[]
  }
}

const brandIcons = {
  'PH-SHELL': ShellJpg,
  'PH-PETRON': PetronJpg,
  'PH-PHOENIX': PhoenixJpg,
  'PH-CALTEX': CaltexJpg,
  'PH-SEAOIL': SeaOilJpg,
  'PH-UNIOIL': UnioilJpg,
  'PH-TOTAL': TotalJpg,
} as any

const TabsContainer = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
    }
  `}
`

type TabKeys = 'products' | 'transactions' | 'engagement' | 'operations' | 'businessInfo' | 'settings' 
const tabKeyLabel = {
  products: 'Products',
  transactions: 'Transactions',
  engagement: 'Engagement',
  operations: 'Operations',
  businessInfo: 'Business Info',
  settings: 'Settings'
}
const getTabsSettings = (currentUser: Types.User | null, fuelStationId: string) => {
  const showTabs = {
    products: false,
    transactions: false,
    engagement: false,
    operations: false,
    businessInfo: false,
    settings: false,
  }
  let tabList: string[] = []

  if (!currentUser?.isFewlsyStaff) {
    if (currentUser?.businessAccount?.isAdmin) {
      showTabs.products = true
      showTabs.transactions = true
      showTabs.engagement = true
      showTabs.operations = true
      showTabs.businessInfo = true
      showTabs.settings = true
      tabList = ['Products', 'Transactions', 'Engagement', 'Operations', 'Business Info', 'Settings']
    }
  
    if (!currentUser?.businessAccount?.isAdmin && currentUser?.businessAccount?.permissions) {
      const currentUserPermissions = currentUser.businessAccount.permissions.filter(p => p.type === 'fuel-station-permission' && p.fuelStationId === fuelStationId)[0]
      if (currentUserPermissions && currentUserPermissions.type === 'fuel-station-permission') {
        if (currentUserPermissions.products) {
          showTabs.products = true
          tabList.push('Products')
        }
  
        if (currentUserPermissions.transactions) {
          showTabs.transactions = true
          tabList.push('Transactions')
        }

        if (currentUserPermissions.engagement) {
          showTabs.engagement = true
          tabList.push('Engagement')
        }
  
        if (currentUserPermissions.operations) {
          showTabs.operations = true
          tabList.push('Operations')
        }
  
        if (currentUserPermissions.businessInfo) {
          showTabs.businessInfo = true
          tabList.push('Business Info')
        }
      }
    }
  }

  if (currentUser?.isFewlsyStaff) {
    showTabs.products = false
    showTabs.transactions = false
    showTabs.engagement = false
    showTabs.operations = false
    showTabs.businessInfo = false
    showTabs.settings = false
    tabList = []
  }

  return { tabList, showTabs }
}

export const hasUserConfiguredOperations = (fuelStation: Types.FuelStation | undefined | null): boolean => {
  if (!fuelStation) {
    return false
  }

  const { isOpen247, daysOpen } = fuelStation
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = daysOpen

  let operationsConfigured = false 

  if (isOpen247 === true
    || monday.isOpen === true
    || tuesday.isOpen === true
    || wednesday.isOpen === true
    || thursday.isOpen === true
    || friday.isOpen === true
    || saturday.isOpen === true
    || sunday.isOpen === true) {
    operationsConfigured = true
  }

  return operationsConfigured
}

export default function FuelStation() {
  const { currentUser } = useUser()
  const { getBrandByCode } = useFuelStationBrand()
  const { getCoreApiClient } = useCoreApi()
  const { fuelStationId, businessAccountId } = useParams()

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [fuelStation, setFuelStation] = useState<Types.FuelStation>()
  const [activeTab, setActiveTab] = useState(0)
  const [fuelStationNotFound, setFuelStationNotFound] = useState<boolean>(false)
  const [hashTabChecked, setHashTabChecked] = useState<boolean>(false)

  const changeTab = (tabKey: TabKeys) => {
    const { tabList } = getTabsSettings(currentUser, fuelStationId || '')

    const tabLabel = tabKeyLabel[tabKey]
    const tabIndex = tabList.indexOf(tabLabel)

    window.location.hash = `#${tabKey}`
    setActiveTab(tabIndex)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const { tabList } = getTabsSettings(currentUser, fuelStationId || '')
    const tabSelected = tabList[newValue]
    let tabKeySelected = ''
    Object.keys(tabKeyLabel).forEach((k) => {
      const key = k as TabKeys
      if (tabKeyLabel[key] === tabSelected) {
        tabKeySelected = key
      }
    })

    window.location.hash = `#${tabKeySelected}`
    setActiveTab(newValue)
  }

  useEffect(() => {
    let isSubscribed = true

    /**
     * Get fuel station info on page load
     */
    const effect = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getFuelStationResponse = await coreApi.get(`/business/${businessAccountId}/fuel-stations/${fuelStationId}`) as GetFuelStationResponse
  
        if (!isSubscribed) {
          return false
        }
  
        if (getFuelStationResponse.data.result) {
          setFuelStation({
            ...getFuelStationResponse.data.result
          })
        }
      } catch (err: any) {
        if (err?.response?.data?.error?.code) {
          logger.error('Failed to get fuel station info', { error: err.response.data.error })

          if (err.response.data.error.code === 'FuelStationNotFoundError') {
            setFuelStationNotFound(true)
          }
        }
      }
    }

    if (!fuelStation) {
      effect()
    }

    return () => {
      isSubscribed = false
    }
  }, [fuelStationId, fuelStation, businessAccountId, getCoreApiClient])

  useEffect(() => {
    let isSubscribed = true

    /**
     * Sets the active tab based on hash tab on page load
     */
    const effect = () => {
      if (!isSubscribed) {
        return false
      }

      const hashTab = window.location.hash.split('#')[1] as TabKeys
      if (!['products', 'transactions', 'engagement', 'operations', 'businessInfo', 'settings'].includes(hashTab)) {
        return false
      }

      const { tabList } = getTabsSettings(currentUser, fuelStationId || '')
      if (tabList.indexOf(tabKeyLabel[hashTab]) > -1) {
        setActiveTab(tabList.indexOf(tabKeyLabel[hashTab]))
      }
      setHashTabChecked(true)
    }

    if (!hashTabChecked) {
      effect()
    }

    return () => {
      isSubscribed = false
    }
  }, [setHashTabChecked, hashTabChecked, setActiveTab, currentUser, fuelStationId])

  const { tabList, showTabs } = getTabsSettings(currentUser, fuelStationId || '')
  const isOperationsConfigured = hasUserConfiguredOperations(fuelStation)

  if (fuelStationNotFound) {
    return <Error40X />
  }

  if (!currentUser?.businessAccount?.isAdmin && tabList.length < 1) {
    return <Error40X />
  }

  return <Box>
    <>
      {!fuelStation &&
        <LinearProgress />
      }
    </>
    <>
      {fuelStation &&
        <Box>
          <Box p={2}>
            <Stack direction={mdUp ? 'row' : 'column'} spacing={mdUp ? 2 : 1} alignItems={mdUp ? 'center' : 'flex-start'}>
              <>
                {fuelStation.status === 'REJECTED' &&
                  <Tooltip title="This fuel station location has been rejected." arrow>
                    <Chip label="Rejected" color="error" size="small" />
                  </Tooltip>
                }
              </>
              <>
                {fuelStation.status === 'PENDING_REVIEW' &&
                  <Tooltip title="This fuel station location is still in review. Review process can take up to 3 or more business days." arrow>
                    <Chip label="Pending Review" color="warning" size="small" />
                  </Tooltip>
                }
              </>
              <>
                {fuelStation.status === 'ACTIVE' &&
                  <Chip label="Active" color="info" size="small" />
                }
              </>
              <>
                {brandIcons[fuelStation.brand] &&
                  <img src={brandIcons[fuelStation.brand]} width="auto" height="32" alt={`${getBrandByCode(fuelStation.brand).name} Brand Logo`} />
                }
              </>
              <Typography variant="h6">{fuelStation.label}</Typography>
              <Typography variant="body1">{fuelStation.businessName}</Typography>
              <Typography variant="body1">{getBrandByCode(fuelStation.brand).name}</Typography>
            </Stack>
            <>
              {fuelStation.status === 'REJECTED' &&
                <Box mt={2} display="inline-block">
                  <Alert severity="error">
                    <Box mb={2} maxWidth={600}>
                      <Typography variant="body1">This fuel station location has been rejected by our review team. You can still update your info and resubmit this property for review by going to the BUSINESS INFO tab and clicking "Update Location".</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">The following issues were found:</Typography>
                      <ul>
                        <>
                          {fuelStation?.locationInfoReviewResult?.checks?.isRequiredFieldsComplete === false &&
                            <li><Typography variant="body2">Missing required fields</Typography></li>
                          }
                        </>
                        <>
                          {fuelStation?.locationInfoReviewResult?.checks?.isAddressLatLongCorrect === false &&
                            <li><Typography variant="body2">Invalid geo coordinates</Typography></li>
                          }
                        </>
                        <>
                          {fuelStation?.locationInfoReviewResult?.checks?.hasBusinessPermit === false &&
                            <li><Typography variant="body2">Did not submit Business Permit</Typography></li>
                          }
                        </>
                        <>
                          {fuelStation?.locationInfoReviewResult?.checks?.hasDtiPermit === false &&
                            <li><Typography variant="body2">Did not submit DTI Permit</Typography></li>
                          }
                        </>
                        <>
                          {fuelStation?.locationInfoReviewResult?.checks?.hasFranchiseAgreement === false &&
                            <li><Typography variant="body2">Did not submit proof of franchise</Typography></li>
                          }
                        </>
                        <>
                          {fuelStation?.locationInfoReviewResult?.checks?.isFormAndDocumentInfoMatch === false &&
                            <li><Typography variant="body2">Submitted info and documents do not match</Typography></li>
                          }
                        </>
                      </ul>
                    </Box>
                    <>
                      {fuelStation?.locationInfoReviewResult?.notes &&
                        <Box>
                          <Typography variant="body2">Reviewer Notes:</Typography>
                          <Typography>{`"${fuelStation?.locationInfoReviewResult?.notes}"`}</Typography>
                        </Box>
                      }
                    </>
                  </Alert>
                </Box>
              }
            </>
          </Box>
          <>
            {(showTabs.operations && isOperationsConfigured === false) &&
              <Box p={2}>
                <Alert severity="warning">
                  <Box mb={2}>
                    <Typography>TIP: Setup the operations info of your fuel station and get the most out of Fewlsy.</Typography>
                  </Box>
                  <Button variant="contained" onClick={() => changeTab('operations')}>Go to Operations</Button>
                </Alert>
              </Box>
            }
          </>
          <TabsContainer>
            <Tabs value={activeTab} onChange={handleTabChange} scrollButtons="auto" variant="scrollable">
              {
                tabList.map((e, i) => {
                  return <Tab key={i} label={e} />
                })
              }
            </Tabs>
          </TabsContainer>
          <Box>
            <>
              {tabList[activeTab] === 'Products' && showTabs.products &&
                <FuelStationProducts fuelStationId={fuelStation.uuid} fuelStation={fuelStation} />
              }
            </>
          </Box>
          <Box>
            <>
              {tabList[activeTab] === 'Transactions' && showTabs.transactions &&
                <FuelStationTransactions fuelStation={fuelStation} />
              }
            </>
          </Box>
          <Box>
            <>
              {tabList[activeTab] === 'Engagement' && showTabs.engagement &&
                <FuelStationEngagement fuelStation={fuelStation} />
              }
            </>
          </Box>
          <Box>
            <>
              {tabList[activeTab] === 'Operations' && showTabs.operations &&
                <FuelStationOperations fuelStation={fuelStation} />
              }
            </>
          </Box>
          <Box>
            <>
              {tabList[activeTab] === 'Business Info' && showTabs.businessInfo &&
                <FuelStationLocation type="UPDATE" fuelStation={fuelStation} />
              }
            </>
          </Box>
          <Box>
            <>
              {tabList[activeTab] === 'Settings' && showTabs.settings &&
                <FuelStationSettings fuelStation={fuelStation} />
              }
            </>
          </Box>
        </Box>
      }
    </>
  </Box>
}
