import React, { useState, useEffect, useRef } from 'react'
import errcode from 'err-code'
import QRCode from 'qrcode'

import { Theme, useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'

import Alert from '@mui/material/Alert'
import Box, { BoxProps } from '@mui/material/Box'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import MuiPaper, { PaperProps } from '@mui/material/Paper'
import Backdrop from '@mui/material/Backdrop'

import useCoreApi from '../hooks/useCoreApi'
import { useUser } from '../hooks/useUser'
import Logger from '../services/logger'
import * as Types from '../types'
import FuelStationTransactionHistory from './FuelStationTransactionHistory'

const logger = new Logger({
  filePath: '@/components/FuelStationTransactions'
})

type StyledTheme = {
  theme: Theme,
}

type FuelStationTransactionsProps = {
  fuelStation?: Types.FuelStation
}

type FuelProductsList = {
  [key: string]: Types.FuelProduct
}

type GetFuelProductsResponse = {
  data: {
    result?: Types.FuelProduct[]
  }
}

type CreateFuelStationTransactionResponse = {
  data: {
    result?: Types.Transaction
  }
}

type FuelProductKeys = 'DIESEL_REGULAR' | 'DIESEL_PREMIUM' | 'GASOLINE_REGULAR' | 'GASOLINE_PREMIUM'

type FormErrors = {
  fuelProductPurchased?: 'FIELD_REQUIRED'
  unitsPurchased?: 'FIELD_REQUIRED'
  submit?: 'REQUEST_FAILED'
}

const FuelStationContainer = styled(Container)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      margin-top: ${theme.spacing(3)};
      margin-bottom: ${theme.spacing(3)};
      margin-left: 0;
      margin-right: 0;
    }
  `}
`

const FuelStationFormContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

const Paper = styled(MuiPaper)<PaperProps>(({ theme }) => ({
  minHeight: '100%',
  width: '100%',
  overflow: 'none',
  [theme.breakpoints.up('md')]: {
    minWidth: '450px',
    overflow: 'auto',
  },
}))

const CreateTransactionSkeleton = styled(Skeleton)`
  ${({ theme }: StyledTheme) => `
    &.MuiSkeleton-root {
      margin-right: ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(2)};
    }
  `}
`

const fuelTypes = { DIESEL: 'Diesel', GASOLINE: 'Gasoline' }
const fuelSubtypes = { REGULAR: 'Regular', PREMIUM: 'Premium' }

export default function FuelStationTransactions(props: FuelStationTransactionsProps) {
  const { fuelStation } = props
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { getCoreApiClient } = useCoreApi()
  const { currentUser } = useUser()

  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [fuelProducts, setFuelProducts] = useState<FuelProductsList>()
  const [fuelStationTransactionQR, setFuelStationTransactionQR] = useState<string>()
  const [transactionId, setTransactionId] = useState<string>()
  
  const [transactionsLastUpdated, setTransactionsLastUpdated] = useState<Date>()

  const fuelProductPurchasedRef = useRef<TextFieldProps>(null)
  const unitsPurchasedRef = useRef<TextFieldProps>(null)

  const handleOnQrBackdropClose = () => {
    setFuelStationTransactionQR(undefined)
    setTransactionId(undefined)
  }

  const handleOnGenerateTransactionQRCode = async (fuelStationTransactionId: string) => {
    const qrDataUrl = await QRCode.toDataURL(fuelStationTransactionId, {
      margin: 0,
      errorCorrectionLevel: 'L'
    })

    setFuelStationTransactionQR(qrDataUrl)
  }

  const handleOnGenerateTransactionPress = async () => {
    try {
      if (!fuelStation || !fuelProducts) {
        return false
      }
  
      setIsFormDisabled(true)
  
      if (!fuelProductPurchasedRef.current || !unitsPurchasedRef.current) {
        logger.error('Reference to form field is missing', {
          refs: [fuelProductPurchasedRef.current, unitsPurchasedRef.current]
        })
        throw new Error('Reference to form field is missing')
      }
  
      const fuelProductPurchased = fuelProductPurchasedRef.current.value as string
      const unitsPurchased = unitsPurchasedRef.current.value as string
  
      let formErrorsFound: FormErrors = {}
  
  
      if (fuelProductPurchased.length < 1) {
        formErrorsFound.fuelProductPurchased = 'FIELD_REQUIRED'
      }
  
      if (unitsPurchased.length < 1) {
        formErrorsFound.unitsPurchased = 'FIELD_REQUIRED'
      }
  
      if (Object.keys(formErrorsFound).length > 0) {
        setIsFormDisabled(false)
        throw errcode(new Error('Form validation errors found'), 'FormSubmitError', { formErrorsFound })
      }

      setFormErrors({})

      const coreApi = await getCoreApiClient()
      const createFuelStationTransactionResponse = await coreApi.post(`/business/${fuelStation.businessAccountId}/fuel-stations/${fuelStation.uuid}/transactions`, {
        fuelProductId: fuelProducts[fuelProductPurchased].uuid,
        fuelUnits: parseFloat(parseFloat(unitsPurchased).toFixed(4)),
      }) as CreateFuelStationTransactionResponse

      if (!createFuelStationTransactionResponse.data.result) {
        throw errcode(new Error('Failed to create fuel station transaction'), 'FormSubmitError', {})
      }

      setTransactionId(createFuelStationTransactionResponse.data.result.uuid)

      fuelProductPurchasedRef.current.value = ''
      unitsPurchasedRef.current.value = ''
      
      setTransactionsLastUpdated(new Date())

      setIsFormDisabled(false)
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data?.error?.message || err.message

      logger.error('Create Purchase Transaction Failed', { errMessage, errCode, formErrorsFound: err.formErrorsFound })

      let formErrorsFound: FormErrors = {}

      if (errCode === 'FormSubmitError') {
        setFormErrors({
          ...err.formErrorsFound
        })
        setIsFormDisabled(false)
        return
      }

      setFormErrors({ ...formErrorsFound })
      setIsFormDisabled(false)
    }
  }

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      if (!fuelStation) {
        return false
      }

      const { businessAccountId, uuid } = fuelStation
      const coreApi = await getCoreApiClient()
      const getFuelProductsResponse = await coreApi.get(`/business/${businessAccountId}/fuel-stations/${uuid}/fuel-products`) as GetFuelProductsResponse

      if (!isSubscribed) {
        return false
      }

      if (getFuelProductsResponse.data.result) {
        const newFuelProducts: FuelProductsList = {}
        getFuelProductsResponse.data.result.forEach((fuelProduct) => {
          const fuelProductKey: FuelProductKeys = `${fuelProduct.type}_${fuelProduct.subtype}`
          newFuelProducts[fuelProductKey] = fuelProduct
        })

        setFuelProducts(newFuelProducts)
      }
    }

    if (!fuelProducts && fuelStation) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [fuelProducts, fuelStation, getCoreApiClient])

  useEffect(() => {
    if (fuelStation && !transactionsLastUpdated) {
      setTransactionsLastUpdated(new Date())
    }
  }, [fuelStation, getCoreApiClient, transactionsLastUpdated])

  const fuelProductKeyList: FuelProductKeys[] = ['DIESEL_REGULAR', 'DIESEL_PREMIUM', 'GASOLINE_REGULAR', 'GASOLINE_PREMIUM']

  let isEditor = true

  if (!currentUser?.businessAccount?.isAdmin && currentUser?.businessAccount?.permissions && fuelStation) {
    const currentUserPermissions = currentUser.businessAccount.permissions.filter(p => p.type === 'fuel-station-permission' && p.fuelStationId === fuelStation.uuid)[0]
    if (currentUserPermissions && currentUserPermissions.type === 'fuel-station-permission') {
      if (currentUserPermissions.transactions === 'VIEWER') {
        isEditor = false
      }
    }
  }

  return <>
    <FuelStationContainer>
      <Box mb={2}>
        <Typography variant="h5">Create Transactions</Typography>
        <Typography color="#888888">Helps track transactions for you and your customers</Typography>
      </Box>
      <FuelStationFormContainer>
        <>
          {!fuelProducts &&
            <Box maxWidth={450} width="100%">
              <CreateTransactionSkeleton variant="rounded" width="100%" height={250} />
            </Box>
          }
        </>
        <>      
          {(fuelProducts && isEditor) &&
            <>
              <Box mr={mdUp ? 5 : 0} width="100%" maxWidth={mdUp ? 450 : 'none'}>
                <Paper>
                  <Box p={mdUp ? 3 : 2}>
                    <Box mb={3}>
                      <Typography variant="h6">Fuel Purchase Transaction</Typography>
                    </Box>
                    <>
                      {transactionId &&
                        <Box mb={3}>
                          <Box mb={2}>
                            <Typography variant="body1">Does the customer want to record the transaction?</Typography>
                          </Box>
                          <>
                            {!fuelStationTransactionQR &&
                              <Box>
                                <Button variant="contained" onClick={() => handleOnGenerateTransactionQRCode(transactionId)}>Generate Transaction QR</Button>
                              </Box>
                            }
                          </>
                        </Box>
                      }
                    </>
                    <>
                      {!transactionId &&
                        <Stack direction="column" spacing={3}>
                          <Box>
                            <TextField
                              fullWidth
                              select
                              label="Fuel Product Purchased"
                              defaultValue=""
                              color="primary"
                              name="txn-fuel-purchased"
                              inputRef={fuelProductPurchasedRef}
                              disabled={isFormDisabled}
                              error={formErrors.fuelProductPurchased === 'FIELD_REQUIRED'}
                              helperText={formErrors.fuelProductPurchased === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                            >
                              {
                                fuelProductKeyList.map((fuelProductKey) => {
                                  const fuelProduct = fuelProducts[fuelProductKey]
                                  return <MenuItem key={fuelProductKey} value={fuelProductKey}>{`${fuelTypes[fuelProduct.type]} ${fuelSubtypes[fuelProduct.subtype]}`}</MenuItem>
                                })
                              }
                            </TextField>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              type="number"
                              label="Liters Purchased"
                              variant="outlined"
                              name="txn-liters-purchased"
                              InputProps={{
                                endAdornment: <Box ml={1}><InputAdornment position="start">liters</InputAdornment></Box>,
                              }}
                              inputRef={unitsPurchasedRef}
                              disabled={isFormDisabled}
                              error={formErrors.unitsPurchased === 'FIELD_REQUIRED'}
                              helperText={formErrors.unitsPurchased === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                            />
                          </Box>
                        </Stack>
                      }
                    </>
                  </Box>
                </Paper>
              </Box>
              <>
                {!transactionId &&
                  <Box mt={mdUp ? 0 : 2} width={mdUp ? 'auto' : '100%'}>
                    <Button fullWidth={!mdUp} disabled={isFormDisabled} variant="contained" type="button" onClick={handleOnGenerateTransactionPress}>Create Transaction</Button>
                  </Box>
                }
              </>
            </>
          }
        </>
        <>
          {(fuelProducts && !isEditor) &&
            <Box>
              <Alert severity="info">
                <Typography>User must have <b>{`Transactions > Editor`}</b> permission to create transactions.</Typography>
              </Alert>
            </Box>
          }
        </>
      </FuelStationFormContainer>
      <>
        {fuelStationTransactionQR &&
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#FFFFFF' }}
            open={true}
            onClick={() => { return null }}
          >
            <Box display="flex" flexDirection="column">
              <Stack direction="column" spacing={3}>
                <Box width="100%" maxWidth={300}>
                  <Typography>Ask the customer to scan the QR code using the Fewlsy app on their phone.</Typography>
                </Box>
                <Box width="100%" maxWidth={300} border="solid" sx={{ backgroundColor: '#FFFFFF' }} my={2} py={8} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <Box>
                    <img src={fuelStationTransactionQR} width="auto" height="auto" alt="Fuel Station Transaction QR Code" />
                  </Box>
                </Box>
                <Box>
                  <Button fullWidth variant="contained" onClick={handleOnQrBackdropClose}>Scan Complete</Button>
                </Box>
              </Stack>
            </Box>
          </Backdrop>
        }
      </>
    </FuelStationContainer>
    <>
      {(transactionsLastUpdated && fuelProducts) &&
        <FuelStationTransactionHistory lastUpdated={transactionsLastUpdated} />
      }
    </>
  </>
}
