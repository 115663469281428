import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import QRCode from 'qrcode'

import { Theme } from '@mui/material/styles'
import styled from 'styled-components'

import Box from '@mui/material/Box'
import MuiContainer from '@mui/material/Container'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Skeleton from '@mui/material/Skeleton'

import QrCode2Icon from '@mui/icons-material/QrCode2'

import Logger from '../services/logger'
import * as Types from '../types'
import useCoreApi from '../hooks/useCoreApi'

const logger = new Logger({
  filePath: '@/components/FuelStationTransactionHistory'
})

type StyledTheme = {
  theme: Theme,
}

type FuelProductsList = {
  [key: string]: Types.FuelProduct
}

type GetFuelStationTransactionsResponse = {
  data: {
    result?: Types.Transaction[]
  }
}

type GetFuelProductsResponse = {
  data: {
    result?: Types.FuelProduct[]
  }
}

type FuelStationTransactionHistoryProps = {
  lastUpdated: Date
}

const Container = styled(MuiContainer)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      margin-top: ${theme.spacing(5)};
      margin-bottom: ${theme.spacing(5)};
      margin-left: 0;
      margin-right: 0;
    }
  `}
`

const fuelTypes = { DIESEL: 'Diesel', GASOLINE: 'Gasoline' }
const fuelSubtypes = { REGULAR: 'Regular', PREMIUM: 'Premium' }

export default function FuelStationTransactionHistory(props: FuelStationTransactionHistoryProps) {
  const { lastUpdated } = props

  const { businessAccountId, fuelStationId } = useParams()
  const { getCoreApiClient } = useCoreApi()

  const [fuelProducts, setFuelProducts] = useState<FuelProductsList>()
  const [data, setData] = useState<Types.Transaction[]>()
  const [lastFetch, setLastFetch] = useState<Date>(new Date())
  const [fuelStationTransactionQR, setFuelStationTransactionQR] = useState<string>()

  const handleOnQrBackdropClose = () => {
    setFuelStationTransactionQR(undefined)
  }

  const handleOnGenerateTransactionQRCode = async (fuelStationTransactionId: string) => {
    const qrDataUrl = await QRCode.toDataURL(fuelStationTransactionId, {
      margin: 0,
      errorCorrectionLevel: 'L'
    })

    setFuelStationTransactionQR(qrDataUrl)
  }

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getFuelStationTransactionsResponse = await coreApi.get(`/business/${businessAccountId}/fuel-stations/${fuelStationId}/transactions`) as GetFuelStationTransactionsResponse
  
        if (!isSubscribed) {
          return
        }
  
        if (getFuelStationTransactionsResponse?.data?.result) {
          setData(getFuelStationTransactionsResponse.data.result)
          setLastFetch(new Date())
        }
      } catch (err) {
        logger.error('FuelStationProductHistory failed to initialize', { err })
      }
    }

    if (!data || lastFetch < lastUpdated) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [data, getCoreApiClient, businessAccountId, fuelStationId, lastUpdated, lastFetch])

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      const coreApi = await getCoreApiClient()
      const getFuelProductsResponse = await coreApi.get(`/business/${businessAccountId}/fuel-stations/${fuelStationId}/fuel-products`) as GetFuelProductsResponse

      if (!isSubscribed) {
        return false
      }

      if (getFuelProductsResponse.data.result) {
        const newFuelProducts: FuelProductsList = {}
        getFuelProductsResponse.data.result.forEach((fuelProduct) => {
          newFuelProducts[fuelProduct.uuid] = fuelProduct
        })

        setFuelProducts(newFuelProducts)
      }
    }

    if (!fuelProducts) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [fuelProducts, businessAccountId, fuelStationId, getCoreApiClient])

  if (!data || !fuelProducts) {
    return <Container>
      <Skeleton variant="rounded" width="100%" height={300} style={{ maxWidth: 800 }} />
    </Container>
  }

  return <Container>
    <Box mb={3}>
      <Typography variant="h5" component="p">Transaction History</Typography>
      <Typography variant="body2" component="p" color="GrayText">Only the 15 most recent transactions will be displayed.</Typography>
    </Box>
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Transaction ID</TableCell>
              <TableCell align="right">Fuel Product ID</TableCell>
              <TableCell align="right">Fuel Price</TableCell>
              <TableCell align="right">Fuel Units Purchased</TableCell>
              <TableCell align="right">Customer</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((transaction) => {
              let fuelProduct: Types.FuelProduct = fuelProducts[transaction.fuelProductId]
              const productLabel = `${fuelTypes[fuelProduct.type]} ${fuelSubtypes[fuelProduct.subtype]}`

              return <TableRow
                key={transaction.uuid}
              >
                <TableCell>{transaction.uuid}</TableCell>
                <TableCell align="right">{productLabel}</TableCell>
                <TableCell align="right">{transaction.fuelUnitPrice}</TableCell>
                <TableCell align="right">{transaction.fuelUnits}</TableCell>
                <TableCell align="right">{transaction.customerUserId ? 'Recorded' : '-'}</TableCell>
                <TableCell align="right">{transaction.createdAt ? new Date(transaction.createdAt).toDateString() : ''}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOnGenerateTransactionQRCode(transaction.uuid)}>
                    <QrCode2Icon />
                  </IconButton>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    <>
      {fuelStationTransactionQR &&
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#FFFFFF' }}
          open={true}
          onClick={() => { return null }}
        >
          <Box display="flex" flexDirection="column">
            <Stack direction="column" spacing={3}>
              <Box width="100%" maxWidth={300}>
                <Typography>Ask the customer to scan the QR code using the Fewlsy app on their phone.</Typography>
              </Box>
              <Box width="100%" maxWidth={300} border="solid" sx={{ backgroundColor: '#FFFFFF' }} my={2} py={8} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box>
                  <img src={fuelStationTransactionQR} width="auto" height="auto" alt="Fuel Station Transaction QR Code" />
                </Box>
              </Box>
              <Box>
                <Button fullWidth variant="contained" onClick={handleOnQrBackdropClose}>Scan Complete</Button>
              </Box>
            </Stack>
          </Box>
        </Backdrop>
      }
    </>
  </Container>
}
