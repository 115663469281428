import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import errcode from 'err-code'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MuiPaper, { PaperProps } from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import TextField, { TextFieldProps} from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { Theme, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import styled from 'styled-components'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined'

import Logger from '../services/logger'
import useCoreApi from '../hooks/useCoreApi'
import useUser from '../hooks/useUser'
import usePageRoutes from '../hooks/usePageRoutes'
import { FuelStation } from '../types'
import Error40X from './Error40X'

import PH_PROVINCES from '../data/ph-provinces.json'
import PH_GAS_STATION_BRANDS from '../data/ph-fuel-stations.json'

const logger = new Logger({
  filePath: '@/components/FuelStationLocation'
})

type StyledTheme = {
  theme: Theme,
}

type FormErrors = {
  addressStreet?: 'FIELD_REQUIRED'
  addressBarangay?: 'FIELD_REQUIRED'
  addressCity?: 'FIELD_REQUIRED'
  addressPostalCode?: 'FIELD_REQUIRED'
  addressProvince?: 'FIELD_REQUIRED'
  addressLongitude?: 'FIELD_REQUIRED'
  addressLatitude?: 'FIELD_REQUIRED'
  businessName?: 'FIELD_REQUIRED'
  brand?: 'FIELD_REQUIRED'
  label?: 'FIELD_REQUIRED'
  primaryContactEmail?: 'FIELD_REQUIRED'
  primaryContactPhoneNumber?: 'FIELD_REQUIRED'
  franchiseExpirationYear?: 'FIELD_REQUIRED'
  franchiseExpirationMonth?: 'FIELD_REQUIRED'
  uploadDocuments?: 'FIELD_REQUIRED'
  submit?: 'REQUEST_FAILED' | 'MISSING_BUSINESSACCOUNTID'
}

type FuelStationResponse = {
  data: {
    result?: FuelStation
  }
}

type Switches = {
  isDealerOperatedFranchise: boolean
}

type DocumentUploads = {
  businessPermit?: string
  dtiPermit?: string
  franchiseProof?: string
}

type DocumentUploadBtnProps = {
  documentUploadsKey?: string
  label: string
  removeDocumentUploadTooltip: string
  inputRef: React.RefObject<HTMLInputElement>
  refKey: 'businessPermit' | 'dtiPermit' | 'franchiseProof'
}

type FuelStationLocationProps = {
  fuelStation?: FuelStation
  type: 'ADD' | 'UPDATE'
}

PH_GAS_STATION_BRANDS.sort(( a, b ) => {
  if ( a.name < b.name ){
    return -1;
  }

  if ( a.name > b.name ){
    return 1;
  }

  return 0;
})

const generateExpirationYears = () => {
  const currentYear = new Date().getFullYear()
  const franchiseExpirationYears = []
  for (let y=0; y < 10; y++) {
    franchiseExpirationYears.push(currentYear + y)
  }
  return franchiseExpirationYears
}

const FuelStationLocationContainer = styled(Container)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      margin-top: ${theme.spacing(3)};
      margin-bottom: ${theme.spacing(3)};
      margin-left: 0;
      margin-right: 0;
    }
  `}
`

const FuelStationFormContainer = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
    }
  `}
`

const RemoveUploadBox = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      padding: ${theme.spacing(1)}
    }
    &.MuiBox-root:hover {
      background-color: #f7f7f7;
    }
  `}
`

const Paper = styled(MuiPaper)<PaperProps>(({ theme }) => ({
  color: theme.palette.success.main,
  minHeight: '100%',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    minWidth: '450px',
  },
}))

export default function FuelStationLocation(props: FuelStationLocationProps) {
  const { type, fuelStation } = props

  const { getCoreApiClient } = useCoreApi()
  const { currentUser } = useUser()
  const navigate = useNavigate()
  const pageRoutes = usePageRoutes()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const Params = useParams()
  const paramsBusinessAccountId = Params.businessAccountId
  const paramsFuelStationId = Params.fuelStationId

  const addressStreetRef = useRef<TextFieldProps>(null)
  const addressBarangayRef = useRef<TextFieldProps>(null)
  const addressCityRef = useRef<TextFieldProps>(null)
  const addressPostalCodeRef = useRef<TextFieldProps>(null)
  const addressProvinceRef = useRef<TextFieldProps>(null)
  const addressLongitudeRef = useRef<TextFieldProps>(null)
  const addressLatitudeRef = useRef<TextFieldProps>(null)

  const businessNameRef = useRef<TextFieldProps>(null)
  const brandRef = useRef<TextFieldProps>(null)
  const labelRef = useRef<TextFieldProps>(null)
  const franchiseExpirationYearRef = useRef<TextFieldProps>(null)
  const franchiseExpirationMonthRef = useRef<TextFieldProps>(null)
  const primaryContactPhoneNumberRef = useRef<TextFieldProps>(null)
  const primaryContactEmailRef = useRef<TextFieldProps>(null)

  const documentBusinessPermit = useRef<HTMLInputElement>(null)
  const documentDtiPermit = useRef<HTMLInputElement>(null)
  const documentFranchiseProof = useRef<HTMLInputElement>(null)

  const [switches, setSwitches] = useState<Switches>({
    isDealerOperatedFranchise: false,
  })
  const [documentUploads, setDocumentUploads] = useState<DocumentUploads>({})

  const [isFranchiseProofRequired, setIsFranchiseProofRequired] = useState(false)
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<FormErrors>({})

  const handleSwitchChange = (key: 'isOpen247' | 'hasSelfServicePumps' | 'isDealerOperatedFranchise', event: React.ChangeEvent<HTMLInputElement>) => {
    const newSwitches = {
      ...switches,
      [key]: event.target.checked
    }

    setSwitches({ ...newSwitches })

    if (key === 'isDealerOperatedFranchise') {
      if (!newSwitches.isDealerOperatedFranchise && documentFranchiseProof.current) {
        documentFranchiseProof.current.value = ''
        setDocumentUploads({
          ...documentUploads,
          franchiseProof: undefined
        })
      }

      if (brandRef?.current?.value) {
        setIsFranchiseProofRequired(brandRef.current.value !== 'other' && newSwitches.isDealerOperatedFranchise)
      }
    }
  }

  const handleBrandChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFranchiseProofRequired(event.target.value !== 'other' && switches.isDealerOperatedFranchise)
  }

  const handleDocumentUploadChange = (key: 'businessPermit' | 'dtiPermit' | 'franchiseProof', ref: React.RefObject<HTMLInputElement>) => {
    if (ref?.current?.value && ref?.current?.files) {
      setDocumentUploads({
        ...documentUploads,
        [key]: ref?.current?.files[0]?.name,
      })
    } else {
      setDocumentUploads({
        ...documentUploads,
        [key]: undefined
      })
    }
  }

  const handleOnDocumentUploadBtnClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref?.current?.click) {
      ref.current.click()
    }
  }

  const handleOnRemoveDocumentUploadBtnClick = (key: 'businessPermit' | 'dtiPermit' | 'franchiseProof', ref: React.RefObject<HTMLInputElement>) => {
    setDocumentUploads({
      ...documentUploads,
      [key]: undefined
    })

    if (ref?.current?.value) {
      ref.current.value = ''
    }
  }

  const submitLocation = async (type: 'CREATE' | 'UPDATE') => {
    try {
      setIsFormDisabled(true)

      if (!addressStreetRef.current ||
        !addressBarangayRef.current ||
        !addressCityRef.current ||
        !addressPostalCodeRef.current ||
        !addressProvinceRef.current ||
        !addressLongitudeRef.current ||
        !addressLatitudeRef.current ||
        !businessNameRef.current ||
        !brandRef.current ||
        !labelRef.current ||
        !primaryContactEmailRef.current ||
        !primaryContactPhoneNumberRef.current ||
        !franchiseExpirationYearRef.current ||
        !franchiseExpirationMonthRef.current
        // !documentBusinessPermit.current ||
        // !documentDtiPermit.current ||
        // (isFranchiseProofRequired && !documentFranchiseProof.current)
      ) {
        logger.error('Reference to form field is missing', {
          refs: [
            addressStreetRef.current,
            addressBarangayRef.current,
            addressCityRef.current,
            addressPostalCodeRef.current,
            addressProvinceRef.current,
            addressLongitudeRef.current,
            addressLatitudeRef.current,
            businessNameRef.current,
            brandRef.current,
            labelRef.current,
            primaryContactEmailRef.current,
            primaryContactPhoneNumberRef.current,
            franchiseExpirationYearRef.current,
            franchiseExpirationMonthRef.current,
            // documentBusinessPermit.current,
            // documentDtiPermit.current,
            // documentFranchiseProof.current,
          ]
        })
        throw new Error('Reference to form field is missing')
      }

      const addressStreet = addressStreetRef.current.value as string
      const addressBarangay = addressBarangayRef.current.value as string
      const addressCity = addressCityRef.current.value as string
      const addressPostalCode = addressPostalCodeRef.current.value as string
      const addressProvince = addressProvinceRef.current.value as string
      const addressLongitude = addressLongitudeRef.current.value as string
      const addressLatitude = addressLatitudeRef.current.value as string

      const businessName = businessNameRef.current.value as string
      const brand = brandRef.current.value as string
      const label = labelRef.current.value as string
      const primaryContactEmail = primaryContactEmailRef.current.value as string
      const primaryContactPhoneNumber = primaryContactPhoneNumberRef.current.value as string
      const franchiseExpirationYear = franchiseExpirationYearRef.current.value as string
      const franchiseExpirationMonth = franchiseExpirationMonthRef.current.value as string

      // const documentBusinessPermitInput = documentBusinessPermit.current
      // const documentDtiPermitInput = documentDtiPermit.current
      // const documentFranchiseProofInput = documentFranchiseProof.current

      let formErrorsFound: FormErrors = {}

      if (!currentUser?.businessAccount?.businessAccountId) {
        formErrorsFound.submit = 'MISSING_BUSINESSACCOUNTID'
      }

      if (addressStreet.trim().length < 1) {
        formErrorsFound.addressStreet = 'FIELD_REQUIRED'
      }

      if (addressBarangay.trim().length < 1) {
        formErrorsFound.addressBarangay = 'FIELD_REQUIRED'
      }

      if (addressCity.trim().length < 1) {
        formErrorsFound.addressCity = 'FIELD_REQUIRED'
      }

      if (addressPostalCode.trim().length < 1) {
        formErrorsFound.addressPostalCode = 'FIELD_REQUIRED'
      }

      if (addressProvince.trim().length < 1) {
        formErrorsFound.addressProvince = 'FIELD_REQUIRED'
      }

      if (addressLongitude.trim().length < 1) {
        formErrorsFound.addressLongitude = 'FIELD_REQUIRED'
      }

      if (addressLatitude.trim().length < 1) {
        formErrorsFound.addressLatitude = 'FIELD_REQUIRED'
      }

      if (businessName.trim().length < 1) {
        formErrorsFound.businessName = 'FIELD_REQUIRED'
      }

      if (brand.trim().length < 1) {
        formErrorsFound.brand = 'FIELD_REQUIRED'
      }

      if (label.trim().length < 1) {
        formErrorsFound.label = 'FIELD_REQUIRED'
      }

      if (primaryContactEmail.trim().length < 1) {
        formErrorsFound.primaryContactEmail = 'FIELD_REQUIRED'
      }

      if (primaryContactPhoneNumber.trim().length < 1) {
        formErrorsFound.primaryContactPhoneNumber = 'FIELD_REQUIRED'
      }

      if (switches.isDealerOperatedFranchise) {
        if (franchiseExpirationYear.length < 1) {
          formErrorsFound.franchiseExpirationYear = 'FIELD_REQUIRED'
        }
  
        if (franchiseExpirationMonth.length < 1) {
          formErrorsFound.franchiseExpirationMonth = 'FIELD_REQUIRED'
        }
      }

      // if (!documentBusinessPermitInput || !documentBusinessPermitInput.files || documentBusinessPermitInput.files.length < 1 || !documentBusinessPermitInput.files[0].name) {
      //   formErrorsFound.uploadDocuments = 'FIELD_REQUIRED'
      // }

      // if (!documentDtiPermitInput || !documentDtiPermitInput.files || documentDtiPermitInput.files.length < 1 || !documentDtiPermitInput.files[0].name) {
      //   formErrorsFound.uploadDocuments = 'FIELD_REQUIRED'
      // }

      // if (isFranchiseProofRequired && (!documentFranchiseProofInput || !documentFranchiseProofInput.files || documentFranchiseProofInput.files.length < 1 || !documentFranchiseProofInput.files[0].name)) {
      //   formErrorsFound.uploadDocuments = 'FIELD_REQUIRED'
      // }

      if (Object.keys(formErrorsFound).length > 0) {
        setIsFormDisabled(false)
        throw errcode(new Error('Form validation errors found'), 'FormSubmitError', { formErrorsFound })
      }

      // No errors found
      setFormErrors({})

      const { isDealerOperatedFranchise } = switches

      const payload = {
        businessAccountId: currentUser?.businessAccount?.businessAccountId,
        addressStreet,
        addressBarangay,
        addressCity,
        addressPostalCode,
        addressProvince,
        addressLongitude,
        addressLatitude,
        addressCountry: 'PH',
        businessName,
        brand,
        label,
        isDealerOperatedFranchise,
        primaryContact: {
          email: primaryContactEmail,
          phoneNumber: primaryContactPhoneNumber,
        },
        franchiseExpiration: isDealerOperatedFranchise ? {
          year: franchiseExpirationYear,
          month: franchiseExpirationMonth,
        } : undefined
      }

      const coreApi = await getCoreApiClient()

      if (type === 'CREATE') {
        const createFuelStationResponse = await coreApi.post(`/business/${paramsBusinessAccountId}/fuel-stations`, {
          ...payload,
        }) as FuelStationResponse
  
        if (!createFuelStationResponse?.data?.result?.uuid) {
          throw errcode(new Error('Failed to add fuel station location'), 'InvalidResponse')
        }

        const privatePages = pageRoutes.getPrivatePages(paramsBusinessAccountId)
        navigate(`${privatePages.fuelStations}/${createFuelStationResponse.data.result.uuid}`, { replace: true })
      }

      if (type === 'UPDATE') {
        const putFuelStationResponse = await coreApi.put(`/business/${paramsBusinessAccountId}/fuel-stations/${paramsFuelStationId}`, {
          ...payload,
        }) as FuelStationResponse
  
        if (!putFuelStationResponse?.data?.result?.uuid) {
          throw errcode(new Error('Failed to update fuel station location'), 'InvalidResponse')
        }

        window.location.reload()
      }
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data?.error?.message || err.message

      logger.error('Add location failed', { errMessage, errCode })

      let formErrorsFound: FormErrors = {}

      if (errCode === 'FormSubmitError') {
        setFormErrors({
          ...err.formErrorsFound
        })
        setIsFormDisabled(false)
        return
      }

      formErrorsFound.submit = 'REQUEST_FAILED'

      setFormErrors({ ...formErrorsFound })
      setIsFormDisabled(false)
    }
  }

  const handleOnSubmitClick = (type: 'CREATE' | 'UPDATE') => {
    submitLocation(type)
  }

  const DocumentUploadBtn = (options: DocumentUploadBtnProps) => {
    const {
      documentUploadsKey,
      label,
      removeDocumentUploadTooltip,
      inputRef,
      refKey
    } = options

    return <>
      <>
        {documentUploadsKey &&
          <RemoveUploadBox>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="caption" color="GrayText">{label}</Typography>
                <Typography color="primary">{documentUploadsKey}</Typography>
              </Stack>
              <Tooltip title={removeDocumentUploadTooltip} arrow>
                <IconButton color="error" disabled={isFormDisabled} onClick={() => handleOnRemoveDocumentUploadBtnClick(refKey, inputRef)}>
                  <DoNotDisturbOnOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </RemoveUploadBox>
        }
      </>
      <>
        {!documentUploadsKey &&
          <Button
            fullWidth
            variant="outlined"
            color={formErrors.uploadDocuments ? 'error' : 'primary'}
            disabled={isFormDisabled}
            onClick={() => handleOnDocumentUploadBtnClick(inputRef)}
          >{label}</Button>
        }
      </>
    </>
  }

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      try {
        if (!isSubscribed) {
          return false
        }

        if (fuelStation?.isDealerOperatedFranchise) {
          setSwitches({
            isDealerOperatedFranchise: fuelStation.isDealerOperatedFranchise,
          })
        }

        if (fuelStation?.status === 'PENDING_REVIEW' || currentUser?.isFewlsyStaff) {
          setIsFormDisabled(true)
        }
      } catch (err) {
        logger.error('Failed to load fuel station info')
      }
    }

    if (fuelStation) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [type, fuelStation, currentUser])

  if (type === 'ADD' && currentUser?.isFewlsyStaff) {
    return <Error40X />
  }

  if (type === 'ADD' && currentUser?.businessAccount?.isAdmin === false) {
    return <Error40X />
  }

  return <FuelStationLocationContainer maxWidth={false}>
    <Box mb={mdUp ? 5 : 2} maxWidth={500}>
      <>
        {type === 'ADD' &&
          <>
            <Typography variant="h5">Add Location</Typography>
            <Typography variant="body1" color="#888888">You can manage multiple fuel or service station locations in one Fewlsy Business Account.</Typography>
          </>
        }
      </>
      <>
        {type === 'UPDATE' && fuelStation?.status === 'PENDING_REVIEW' &&
          <>
            <Alert severity="warning">
              <Box mb={1}>
                <Typography variant="body2">This fuel station location is still being reviewed. This information is locked until the review process is completed.</Typography>
              </Box>
              <Box>
                <Typography variant="body2">{`Last updated: ${new Date(fuelStation.updatedAt).toDateString()}`}</Typography>
              </Box>
            </Alert>
          </>
        }
      </>
      <>
        {type === 'UPDATE' && fuelStation?.status === 'ACTIVE' &&
          <>
            <Typography variant="h5">Update Location</Typography>
          </>
        }
      </>
    </Box>
    <FuelStationFormContainer>
      <Box width={mdUp ? 450 : '100%'} mr={mdUp ? 5 : 0} mb={mdUp ? 5 : 2}>
        <Paper>
          <Box p={3}>
            <Box mb={3}>
              <Typography variant="h6">Address Information</Typography>
            </Box>
            <Stack direction="column" spacing={3}>
              <Box>
                <TextField
                  fullWidth
                  size="small"
                  label="Street"
                  name="street"
                  variant="outlined"
                  defaultValue={fuelStation?.addressStreet}
                  disabled={isFormDisabled}
                  inputRef={addressStreetRef}
                  error={formErrors.addressStreet === 'FIELD_REQUIRED'}
                  helperText={formErrors.addressStreet === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  size="small"
                  label="Barangay"
                  name="barangay"
                  variant="outlined"
                  defaultValue={fuelStation?.addressBarangay}
                  disabled={isFormDisabled}
                  inputRef={addressBarangayRef}
                  error={formErrors.addressBarangay === 'FIELD_REQUIRED'}
                  helperText={formErrors.addressBarangay === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  size="small"
                  label="City"
                  name="city"
                  variant="outlined"
                  defaultValue={fuelStation?.addressCity}
                  disabled={isFormDisabled}
                  inputRef={addressCityRef}
                  error={formErrors.addressCity === 'FIELD_REQUIRED'}
                  helperText={formErrors.addressCity === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  size="small"
                  label="Zip Code"
                  name="zipcode"
                  variant="outlined"
                  defaultValue={fuelStation?.addressPostalCode}
                  disabled={isFormDisabled}
                  inputRef={addressPostalCodeRef}
                  error={formErrors.addressPostalCode === 'FIELD_REQUIRED'}
                  helperText={formErrors.addressPostalCode === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  select
                  size="small"
                  label="Province"
                  name="province"
                  color="primary"
                  defaultValue={fuelStation?.addressProvince || ''}
                  disabled={isFormDisabled}
                  inputRef={addressProvinceRef}
                  error={formErrors.addressProvince === 'FIELD_REQUIRED'}
                  helperText={formErrors.addressProvince === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                >
                  {
                    PH_PROVINCES.map((province) => {
                      return <MenuItem key={province.code} value={province.code}>{province.name}</MenuItem>
                    })
                  }
                </TextField>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  select
                  size="small"
                  label="Country"
                  name="country"
                  defaultValue="PH"
                  color="primary"
                  disabled
                  helperText="We are still working to make Fewlsy Business available in other countries"
                >
                  <MenuItem value="PH">Philippines</MenuItem>
                </TextField>
              </Box>
              <Box>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body1">Map GPS Coordinates</Typography>
                  <Tooltip title="Set the latitude and longitude coordinates of your fuel station location, be as accurate as possible" arrow>
                    <InfoOutlinedIcon color="action" />
                  </Tooltip>
                </Stack>
                <Typography variant="body2" component="a" href="#TODO" target="_blank" rel="noreferrer">How to get geo coordinates</Typography>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  size="small"
                  label="Latitude"
                  name="latitude"
                  variant="outlined"
                  defaultValue={fuelStation?.addressLatitude}
                  disabled={isFormDisabled}
                  inputRef={addressLatitudeRef}
                  error={formErrors.addressLatitude === 'FIELD_REQUIRED'}
                  helperText={formErrors.addressLatitude === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  size="small"
                  label="Longitude"
                  name="longitude"
                  variant="outlined"
                  defaultValue={fuelStation?.addressLongitude}
                  disabled={isFormDisabled}
                  inputRef={addressLongitudeRef}
                  error={formErrors.addressLongitude === 'FIELD_REQUIRED'}
                  helperText={formErrors.addressLongitude === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                />
              </Box>
              <>
                {addressLatitudeRef?.current?.value && addressLongitudeRef?.current?.value &&
                  <Box display="inline-block">
                    <Button variant="outlined" color="secondary" component="a" href={`https://maps.google.com/?q=${addressLatitudeRef.current.value},${addressLongitudeRef.current.value}`} target="_blank" rel="noreferrer">Preview Map</Button>
                  </Box>
                }
              </>
            </Stack>
          </Box>
        </Paper>
      </Box>
      <Box width={mdUp ? 450 : '100%'} mr={mdUp ? 5 : 0} mb={mdUp ? 5 : 2}>
        <Paper>
          <Box p={3}>
            <Box mb={3}>
              <Typography variant="h6">Business Information</Typography>
            </Box>
            <Box mb={5}>
              <Stack direction="column" spacing={3}>
                <Box>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body1">Publicly Displayed</Typography>
                    <Tooltip title="Will be used in the Fewlsy platform and can be seen by you and other users" arrow>
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Stack>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="Business Name"
                    name="business-name"
                    variant="outlined"
                    defaultValue={fuelStation?.businessName}
                    disabled={isFormDisabled}
                    inputRef={businessNameRef}
                    error={formErrors.businessName === 'FIELD_REQUIRED'}
                    helperText={formErrors.businessName === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label="Fuel Station Brand"
                    name="brand"
                    defaultValue={fuelStation?.brand || ''}
                    color="primary"
                    disabled={isFormDisabled}
                    inputRef={brandRef}
                    onChange={handleBrandChange}
                    error={formErrors.brand === 'FIELD_REQUIRED'}
                    helperText={formErrors.brand === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                  >
                    {
                      PH_GAS_STATION_BRANDS.map((fuelStationBrands) => {
                        return <MenuItem key={fuelStationBrands.code} value={fuelStationBrands.code}>{fuelStationBrands.name}</MenuItem>
                      })
                    }
                  </TextField>
                </Box>
              </Stack>
            </Box>
            <Stack direction="column" spacing={3}>
              <Box>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body1">Privately Displayed</Typography>
                  <Tooltip title="Will be used in the Fewlsy Business app and can be seen by you and other users with permission" arrow>
                    <InfoOutlinedIcon color="action" />
                  </Tooltip>
                </Stack>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  size="small"
                  label="Label"
                  name="label"
                  variant="outlined"
                  defaultValue={fuelStation?.label}
                  disabled={isFormDisabled}
                  inputRef={labelRef}
                  error={formErrors.label === 'FIELD_REQUIRED'}
                  helperText={formErrors.label === 'FIELD_REQUIRED' ? 'Field required' : 'To help quickly identify the location within your business account. Helpful when you have multiple locations.'}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  size="small"
                  label="Primary Contact Email"
                  name="primary-contact-email"
                  variant="outlined"
                  defaultValue={fuelStation?.primaryContactEmail}
                  disabled={isFormDisabled}
                  inputRef={primaryContactEmailRef}
                  error={formErrors.primaryContactEmail === 'FIELD_REQUIRED'}
                  helperText={formErrors.primaryContactEmail === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  size="small"
                  label="Primary Contact Phone Number"
                  name="primary-contact-phone"
                  variant="outlined"
                  defaultValue={fuelStation?.primaryContactPhoneNumber}
                  disabled={isFormDisabled}
                  inputRef={primaryContactPhoneNumberRef}
                  error={formErrors.primaryContactPhoneNumber === 'FIELD_REQUIRED'}
                  helperText={formErrors.primaryContactPhoneNumber === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={<Switch
                    checked={switches.isDealerOperatedFranchise}
                    onChange={(e) => handleSwitchChange('isDealerOperatedFranchise', e)}
                    disabled={isFormDisabled}
                  />}
                  label="Is the location a Dealer Operated Franchise?"
                />
              </Box>
              <>
                <Box display={!switches.isDealerOperatedFranchise ? 'none' : 'block'}>
                  <Box mb={1}>
                    <InputLabel>Franchise Expiration</InputLabel>
                  </Box>
                  <Stack direction="row" spacing={3}>
                    <Box width="100%">
                      <TextField
                        fullWidth
                        select
                        size="small"
                        label="Year"
                        name="franchise-expiration-year"
                        color="primary"
                        defaultValue={fuelStation?.franchiseExpirationYear || ''}
                        disabled={isFormDisabled}
                        inputRef={franchiseExpirationYearRef}
                        error={formErrors.franchiseExpirationYear === 'FIELD_REQUIRED'}
                        helperText={formErrors.franchiseExpirationYear === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                      >
                        {
                          generateExpirationYears().map((year) => {
                            return <MenuItem key={year} value={year}>{year}</MenuItem>
                          })
                        }
                      </TextField>
                    </Box>
                    <Box width="100%">
                      <TextField
                        fullWidth
                        select
                        size="small"
                        label="Month"
                        name="franchise-expiration-month"
                        color="primary"
                        defaultValue={fuelStation?.franchiseExpirationMonth !== undefined ? fuelStation.franchiseExpirationMonth : ''}
                        disabled={isFormDisabled}
                        inputRef={franchiseExpirationMonthRef}
                        error={formErrors.franchiseExpirationMonth === 'FIELD_REQUIRED'}
                        helperText={formErrors.franchiseExpirationMonth === 'FIELD_REQUIRED' ? 'Field required' : undefined}
                      >
                        {
                          ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'].map((month, index) => {
                            return <MenuItem key={month} value={index}>{month}</MenuItem>
                          })
                        }
                      </TextField>
                    </Box>
                  </Stack>
                </Box>
              </>
              <Box display="none">
                <Box mb={1}>
                  <Typography variant="body2" color="InactiveCaptionText">Upload Documents</Typography>
                  <>
                    {formErrors.uploadDocuments && !isFranchiseProofRequired &&
                      <FormHelperText error={true}>Upload proof of business</FormHelperText>
                    }
                  </>
                  <>
                    {formErrors.uploadDocuments && isFranchiseProofRequired &&
                      <FormHelperText error={true}>Upload proof of business and franchise</FormHelperText>
                    }
                  </>
                </Box>
                <Stack direction="column" spacing={2}>
                  <DocumentUploadBtn
                    documentUploadsKey={documentUploads.businessPermit}
                    label="Business Permit"
                    removeDocumentUploadTooltip="Remove Business Permit"
                    inputRef={documentBusinessPermit}
                    refKey="businessPermit"
                  />
                  <DocumentUploadBtn
                    documentUploadsKey={documentUploads.dtiPermit}
                    label="DTI Permit"
                    removeDocumentUploadTooltip="Remove DTI Permit"
                    inputRef={documentDtiPermit}
                    refKey="dtiPermit"
                  />
                  <>
                    {isFranchiseProofRequired &&
                      <DocumentUploadBtn
                        documentUploadsKey={documentUploads.franchiseProof}
                        label="Proof of Franchise"
                        removeDocumentUploadTooltip="Remove Proof of Franchise"
                        inputRef={documentFranchiseProof}
                        refKey="franchiseProof"
                      />
                    }
                  </>
                  <Box display="none">
                    <input type="file" ref={documentBusinessPermit} name="businessPermit" accept="image/png, image/jpeg, application/pdf" onChange={() => handleDocumentUploadChange('businessPermit', documentBusinessPermit)} />
                    <input type="file" ref={documentDtiPermit} name="dtiPermit" accept="image/png, image/jpeg, application/pdf" onChange={() => handleDocumentUploadChange('dtiPermit', documentDtiPermit)} />
                    <>
                      {isFranchiseProofRequired &&
                        <input type="file" ref={documentFranchiseProof} name="franchiseProof" accept="image/png, image/jpeg, application/pdf" onChange={() => handleDocumentUploadChange('franchiseProof', documentFranchiseProof)} />
                      }
                    </>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Paper>
      </Box>
      <Box width={mdUp ? 450 : '100%'} mr={mdUp ? 5 : 0} mb={mdUp ? 5 : 2}>
        <Box>
          <>
            {!currentUser?.isFewlsyStaff &&
              <Box>
                <>
                  {formErrors.submit && ['REQUEST_FAILED', 'MISSING_BUSINESSACCOUNTID'].includes(formErrors.submit) &&
                    <Box mb={3}>
                      <Alert severity="error">{type === 'ADD' ? 'Failed to add location. Please refresh the page and try again.' : 'Failed to update location. Please refresh the page and try again.'}</Alert>
                    </Box>
                  }
                </>
                <>
                  {type === 'ADD' &&
                    <>
                      <Button variant="contained" onClick={() => handleOnSubmitClick('CREATE')} disabled={isFormDisabled}>{isFormDisabled ? 'Adding Location...' : 'Add Location'}</Button>
                      <Box mt={3}>
                        <Alert severity="warning">
                          <Box mb={2}>
                            <Typography variant="body2">Once you submit this information, our review team will contact you via the primary contact info you provided.</Typography>
                          </Box>
                          <Typography variant="body2">Please prepare a scanned copy of your Business Permit, DTI Permit, and Proof of Franchise to improve the chances of your location being approved.</Typography>
                        </Alert>
                      </Box>
                    </>
                  }
                </>
                <>
                  {type === 'UPDATE' && (fuelStation?.status === 'ACTIVE' || fuelStation?.status === 'REJECTED') &&
                    <>
                      <Button variant="contained" onClick={() => handleOnSubmitClick('UPDATE')} disabled={isFormDisabled}>{isFormDisabled ? 'Updating Location...' : 'Update Location'}</Button>
                      <Box mt={3}>
                        <Alert severity="warning">All products will be unpublished and your fuel station will be under review when you update this information.</Alert>
                      </Box>
                    </>
                  }
                </>
              </Box>
            }
          </>
        </Box>
      </Box>
    </FuelStationFormContainer>
  </FuelStationLocationContainer>
}
