import React from 'react'
import { Link, useParams } from 'react-router-dom'

// import { Theme } from '@mui/material/styles'
// import styled from 'styled-components'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// import Logger from '../services/logger'
import usePageRoutes from '../hooks/usePageRoutes'
import DashboardContent from './DashboardContent'

// const logger = new Logger({
//   filePath: '@/components/DashboardHome'
// })

// type StyledTheme = {
//   theme: Theme,
// }

// const FuelStationEngagementContainer = styled(Box)`
//   ${({ theme }: StyledTheme) => `
//     &.MuiBox-root {
//       display: flex;
//       flex-wrap: wrap;
//       flex-direction: row;
//       justify-content: flex-start;
//     }
//   `}
// `

export default function DashboardSettings() {
  // const [something, setSomething] = useState(false)
  const { businessAccountId } = useParams()
  const pageRoutes = usePageRoutes()
  const privatePages = pageRoutes.getPrivatePages(businessAccountId)

  return <DashboardContent>
    <Box p={4}>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="h5" component="h1">Welcome to the Fewlsy Business dashboard!</Typography>
        </Box>
        <Box maxWidth={600}>
          <Alert severity="info">
            <Box mb={1}>
              <Typography>Go to fuel stations to get started.</Typography>
            </Box>
            <Button variant="contained" component={Link} to={privatePages.fuelStations}>Fuel Stations</Button>
          </Alert>
        </Box>
      </Stack>
    </Box>
  </DashboardContent>
}
